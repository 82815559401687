export default [
  {
    header: 'Administración',
  },
  {
    title: 'Clientes',
    route: 'apps-users-list',
    icon: 'UserIcon',
  },
]
