export default [
  {
    title: 'Panel de Control',
    icon: 'HomeIcon',
    route: 'main-dashboard',
    /* tag: '2',
    tagVariant: 'light-warning',
    route: 'main-dashboard',
    children: [
      {
        title: 'eCommerce',
        route: 'dashboard-ecommerce',
      },
      {
        title: 'Analytics',
        route: 'dashboard-analytics',
      },
      {
        title: 'Panel de Control',
        route: 'main-dashboard',
      },
    ], */
  },
]
